import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, IconButton, Divider } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import _ from "lodash";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import withSizes from "react-sizes";
import { RoomDetails } from "./index";

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	paper: {
		padding: theme.spacing(2),
		maxWidth: 500
	},
	button: {
		padding: "0.2em"
	},
	image: {
		height: "130px"
	},
	img: {
		margin: "auto",
		display: "block",
		maxWidth: "100%",
		height: "100%",
		objectFit: "cover"
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	options: {
		"&:hover": {
			backgroundColor: theme.palette.secondary.light,
			cursor: "pointer"
		}
	},
	iconbutton: {
		padding: 0,
		"&:hover": {
			backgroundColor: "transparent"
		}
	},
	mydivider: {
		height: 2,
		backgroundColor: theme.palette.secondary.light,
		width: "100%"
	}
});

class Products extends React.Component {
	state = {
		open: false,
		data: []
	};

	componentDidMount() {
		if (this.props.data) {
			this.setState({
				data: this.props.data
			});
		}
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};

	render() {
		const { classes } = this.props;
		const { data } = this.state;

		return (
			<div>
				{data &&
					_.map(data.products, (product, key) => (
						<Grid
							container
							key={key}
							spacing={2}
							style={{ marginBottom: "1em" }}
							className={classes.options}
							onClick={() =>
								this.props.selectionCallback(product)
							}>
							<Grid item xs={4}>
								<Grid container spacing={0}>
									<Grid
										item
										xs={12}
										className={classes.image}>
										<ReactCSSTransitionGroup
											transitionName='loadingItem'
											transitionAppear={true}
											transitionAppearTimeout={500}
											transitionEnterTimeout={500}
											transitionLeaveTimeout={300}>
											<img
												className={classes.img}
												alt={product.title}
												src={product.image}
											/>
										</ReactCSSTransitionGroup>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={7} container>
								<Grid
									item
									xs={12}
									style={{
										paddingBottom: "0em",
										paddingRight: "0em"
									}}>
									<Typography
										// className={classes.options}
										style={{
											fontSize: this.props.isMobile
												? "0.9em"
												: "1em",
											fontWeight: "700",
											overflow: "hidden",
											textOverflow: "ellipsis",
											WebkitLineClamp: "2",
											WebKitBoxOrient: "vertical"
										}}>
										{product.title}
									</Typography>
									<RoomDetails details={product.roomInfo} />
									<Typography
										style={{
											fontSize: "0.8em",
											fontWeight: "700",
											color: "#929292",
											margin: "8px auto 0px"
										}}>
										Dates
									</Typography>
									<Typography
										style={{
											fontSize: "0.9em",
											fontWeight: "700",
											margin: "-3px auto 12px"
										}}>
										{`${data.startDate} - ${data.endDate}`}
									</Typography>
									<Typography
										style={{
											fontSize: "0.8em",
											fontWeight: "700",
											color: "#929292",
											margin: "-3px auto 0px"
										}}>
										Rooms & Guests
									</Typography>
									<Typography
										style={{
											fontSize: "0.9em",
											fontWeight: "700",
											margin: "-3px auto 12px"
										}}>
										{`${data.rooms} Room : ${data.adult} Adult`}
										{data.child === 0
											? null
											: `${data.child} Child`}
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								xs={1}
								container
								className={classes.options}>
								<IconButton
									className={classes.iconbutton}
									onClick={() =>
										this.props.selectionCallback(product)
									}>
									<ChevronRightIcon />
								</IconButton>
							</Grid>
							{key < Number(data.products.length - 1) ? (
								<div className={classes.mydivider}></div>
							) : null}
						</Grid>
					))}
			</div>
		);
	}
}
Products.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(withStyles(styles)(Products));
